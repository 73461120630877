/* WebKit browsers */
.pac-container{
    z-index: 9999;
    box-shadow: 0px 8px 23px #0000001A;
    border-radius: 8px;
    min-width: 400px;
    top:20px;
    margin-top: 40px;
    margin-left: -20px;
}
.pac-item{
    padding:5px;
    cursor: pointer;
}
.pac-container:after{
    background-image: none !important;
    height: 0px;
}

.input_placeholder_color:focus::-webkit-input-placeholder {
    color: #E0E0E0;
  }
  /* Mozilla Firefox 4 to 18 */
  .input_placeholder_color:focus:-moz-placeholder {
    color: #E0E0E0;
  }
  /* Mozilla Firefox 19+ */
  .input_placeholder_color:focus::-moz-placeholder {
    color: #E0E0E0;
  }
  /* Internet Explorer 10+ */
  .input_placeholder_color:focus:-ms-input-placeholder {
    color: #E0E0E0;
  }
  
  .input_placeholder_color::placeholder{
    color: #6F6F6F;
    font-size: 12px;
  }