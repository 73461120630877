/* div {
  white-space: pre-wrap;
} */
m {
  font-weight: bold;
}
/* p span {
  display: block;
} */
.info {
  background-color: #e7f3fe;
  border: 1.5px solid #2196f3;
  border-radius: 25px;
  padding: 35px;
  font-size: 18px;
  text-align: justify;
  text-justify: inter-word;
}
.body {
  width: 99%;
}
.MONO {
  color: black;
}
.SEVLVL {
  color: black;
}
.MECACT {
  color: black;
}
.CLIEFF {
  color: black;
}
.PATMAN {
  color: black;
}
.PFACT {
  color: black;
}
.DISC {
  color: black;
}
.REFC {
  color: black;
}
