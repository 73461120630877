.pl-root {
    margin: 0px !important;
    padding: 15px;
    background:white;
    box-shadow: 0px 10px 25px #0000000A !important;
    border-radius: 8px !important;
}

.rv-pl-root {
    margin: 0px !important;
    padding: 10px;
    background: white;
    box-shadow: rgb(0 0 0 / 4%) 0px 10px 25px !important;
    border-radius: 8px !important;
}

.pv-root {
    padding: 4px 4px 0px 4px;
}

.vr-device-paper {
    margin-top: 4px !important;
}

.en-title {
    font-size: 12px !important;
    font-weight: 600 !important;
    display: inline-block;
    color: #001C3C;
    font-family: 'pc_semibold' !important;
}

.en-actions-root {
    padding: 0px;
}

.pl-detail, .pl-details {
    display: grid !important;
    grid-template-columns: 62px 400px 1fr !important;
    /* padding: 3px; */
}
.pl-detail1, .pl-details1 {
    display: grid !important;
    grid-template-columns: 62px 282px 1fr !important;
    /* padding: 3px; */
}

.pl-details-avatar{
    margin: 0 !important;
    width: 48px !important;
    height: 48px !important;
    background-color: #dee5ec !important;
}

.pl-detail-root {
    padding-left: 0;
}

.pl-details-names {
    margin: auto;
    margin-left: 0;
    margin-top: 0px;
}

.pl-details-locations {
    display: flex;
    align-items: center;
}

.pl-details-names>h6 {
    font-size: 14px;
    line-height: 1.5;
    color: #001C3C;
    margin-bottom: 3px;
    font-family: 'pc_semibold' !important;
}
.pl-details-names>h6>span {
    color: #001C3C;
    font-family: 'pc_regular' !important;
}

.pl-details-names>span {
    font-size: 12px;
    color: #F1F6FC;
    font-family: 'pc_regular' !important;
}

.pl-secondary-text span {
    /* padding-right: 15px; */
    color: #000;
    font-size: 12px !important;
    font-family: 'pc_regular' !important;
    
}

.pl-secondary-text-icons {
    color: #000;
}

.pl-secondary-text{
    margin-right: 20px;
}

.p1-secondary-img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.en-encounter-right {
    border: 1px dashed #B6B6B6;
    border-radius: 8px;
    padding: 10px 0px 18px 19px;
}

.rv-list-view {
    padding: 5px 10px;
    /* background: lightgray; */
    background-image: linear-gradient(to right, #F7ECF4, #DAEAF8);
}

.flexCenter{
    display: flex;
    align-items: center;
}

.iconWrapper{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0071F214;
    border: 1px solid #0071F229;
    margin-right: 15px;
}
.iconWrapper > i{
    color: #0071F2;
}

.textStyle{
    color: #001C3C;
    font-size: 12px !important;
    /* font-weight: 600 !important; */
    font-family: 'pc_medium' !important;
}

.divider{
    border-left: 1px dashed #E0E0E0;
    margin: 10px;
    height: 50%;
}