@font-face {
	font-family: "pc_semibold";
	src: url("./assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
	font-family: "pc_extrabold";
	src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}
@font-face {
	font-family: "pc_regular";
	src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
	font-family: "pc_medium";
	src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
	font-family: "pc_light";
	src: url("./assets/fonts/Poppins-Light.ttf");
}

/* html,body{
	margin:0px;
	padding: 0px;
	overflow-y: hidden;
	overflow-x: hidden;
	height: 100%;
	width: 100%;

} */
.pc_semibold {
	font-family: "pc_semibold" !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  body {
	overflow:hidden;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;
	overflow-y: hidden;
	overflow-x: hidden;  /* Firefox */
  }

  
