.pd-book-btn {
    font-size: 13px !important;
    font-family: 'pc_medium' !important;
}
.pd-book-btn span{
    font-family: 'pc_medium' !important;
}

.label-btn {
    font-size: 12px !important;
    margin: 2px !important;
    padding: 3px 8px;
    position: absolute;
    text-align: center;
    border-radius: 5px;
    top:-38px;

}

.sucess-btn {
    background-color: #f0fcef;
    color: #258007;
}

.error-btn {
    background-color: #fff2f2;
    color: #bf3a37;
}