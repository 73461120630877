
.MuiTableRow-root td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
  }
  
  .MuiTableRow-root td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }