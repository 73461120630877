.onHoverMenu {
  border-radius: 4px;
  cursor: pointer;
}
.onHoverMenu:hover {
  color: #0271f2;
  background: #eee;
}
.onHoverMenu:hover * path {
  fill: #EC6A49 !important;
}
.selectedMenu {
  color: #0271f2;
  background: #e0ecf9;
}
.selectedMenu * path {
  color: #0271f2;
  fill: #EC6A49 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.muiPopperDropdown {
  min-width: 200px !important;
  max-width: 850px !important;
  width: unset !important;
  left: 30px !important;
}
.addReaction * .MuiInput-underline::before {
  border-bottom: none !important;
}

.addReaction * .MuiInput-underline::before :hover {
  border-bottom: none !important;
}

.addReaction * .MuiInput-underline::before:focus {
  border-bottom: none !important;
}

.Mild {
  color: #0071f2 !important;
}
.Moderate {
  color: #f58b00 !important;
}
.Severe {
  color: #ff6360 !important;
}
.usersEdit {
  visibility: hidden;
}
.usersEdit2 {
  visibility: visible;
}
.userInfoEdit {
  /* position: absolute;
    background: #fff;
    transition: all 0.5s;
    right: 0px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: -90px;
  transition: all 0.5s;
}

.userInfoEdit_ {
  /* position: absolute;
    background: #fff;
    transition: all 0.5s;
    right: 0px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: -55px;
  transition: all 0.5s;
}
.allergyListHover {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.allergyListHover:hover * .userInfoEdit {
  right: 10px !important;
}
.allergyListHover:hover * .userInfoEdit_ {
  right: 10px !important;
}

.allergyListHover:hover * .userHide{
  visibility: hidden;
}
.allergyListHover:hover * .userDisplayNone{
  display:none !important;
}

.allergyListHover_ {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.allergyListHover_:hover * .userInfoEdit {
  right: -60px !important;
}

.allergyListHover_:hover * .userHide{
  visibility: hidden;
}
.allergyListHover_:hover * .userDisplayNone{
  display:none !important;
}

.allergyListHover__radiology {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.allergyListHover__radiology:hover * .userInfoEdit {
  right: -15px !important;
}

.allergyListHover__radiology:hover * .userHide{
  visibility: hidden;
}
.allergyListHover__radiology:hover * .userDisplayNone{
  display:none !important;
}

.allergyListHover_medication {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.allergyListHover_medication:hover * .userInfoEdit {
  right: 0px !important;
}

.allergyListHover_medication:hover * .userHide{
  visibility: hidden;
}
.allergyListHover_medication:hover * .userDisplayNone{
  display:none !important;
}

/* status current */
.status-selector .selected{
  color:#fff;
}
.status-selector .selected .bullet{
  background-color:#fff;
}

/* inactive status selection */
.status-selector .inactive{
  background-color: #FDEBD4;
  color:black;
}
.status-selector .selected-inactive{
  background-color:#F58B00;
}
.status-selector .bg-color-inactive{
  background-color:#F58B00;
}
.status-selector .inactive .bullet{
  background-color:#F58B00;
}
.status-selector .color-inactive{
  color:#F58B00;
}

/* active status selection */
.status-selector .active{
  background-color: hsl(105, 62%, 90%);
  color:#38C20A;
}
.status-selector .active .bullet{
  background-color:#38C20A;
}
.status-selector .selected-active{
  background-color:#38C20A;
}
.status-selector .bg-color-active{
  background-color:#38C20A;
}
.status-selector .color-active{
  color:#38C20A;
}


/* resolved status selection */
.status-selector .resolved{
  background-color: #E7E7E7;
  color:#6F6F6F;
}

.status-selector .resolved .bullet{
  background-color:#6F6F6F;
}

.status-selector .selected-resolved{
  background-color:#6F6F6F;
}

.status-selector .bg-color-resolved{
  background-color:#6F6F6F;
}
.status-selector .color-resolved{
  color:#6F6F6F;
}

/* replosed status selection */
.status-selector .replosed{
  background-color: #E3D7F3;
  color:#5B12BB;
}

.status-selector .replosed .bullet{
  background-color:#5B12BB;
}

.status-selector .selected-replosed{
  background-color:#5B12BB;
}
.status-selector .bg-color-replosed{
  background-color:#5B12BB;
}
.status-selector .color-replosed{
  color:#5B12BB;
}



/* relapse status selection */
.status-selector .relapse{
  background-color: #E3D7F3;
  color:#5B12BB;
}

.status-selector .relapse .bullet{
  background-color:#5B12BB;
}

.status-selector .selected-relapse{
  background-color:#5B12BB;
}
.status-selector .bg-color-relapse{
  background-color:#5B12BB;
}
.status-selector .color-relapse{
  color:#5B12BB;
}

/* recurrence status selection */
.status-selector .recurrence{
  background-color: #D4E7FD;
  color:#0071F2;
}

.status-selector .recurrence .bullet{
  background-color:#0071F2;
}
.status-selector .selected-recurrence{
  background-color:#0071F2;
}
.status-selector .bg-color-recurrence{
  background-color:#0071F2;
}
.status-selector .color-recurrence{
  color:#0071F2;
}

/* remission status selection */
.status-selector .remission{
  background-color: #FFE1E0;
  color:#FF4D4A;
}

.status-selector .remission .bullet{
  background-color:#FF4D4A;
}
.status-selector .selected-remission{
  background-color:#FF4D4A;
}
.status-selector .bg-color-remission{
  background-color:#FF4D4A;
}
.status-selector .color-remission{
  color:#FF4D4A;
}

.textInput input:disabled{
  background-color: #fafafa;
  border: solid 1px #9b9b9b;
}

@media screen and (max-width: 1417px) {
  .addReaction {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1131px) {
  .verification {
    margin-top: 10px;
  }
}

@media screen and (max-width: 951px) {
  .mild {
    margin-top: 10px;
  }
}

@media screen and (max-width: 951px) {
  .type {
    margin-top: 10px;
  }
  .search {
    margin-top: 10px;
  }
}
.allergyListHover:hover * .userInfoEdit{
    right: 10px !important;
}
.allergyListHover_:hover * .userInfoEdit{
  right: -60px !important;
}
.allergyListHover__radiology:hover * .userInfoEdit{
  right: -15px !important;
}
.allergyListHover_medication:hover * .userInfoEdit{
  right: 0px !important;
}
.react-datepicker{
    border:0 !important;
}
.react-datepicker__input-time-container{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 10px 0px !important;
}
